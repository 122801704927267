<template>
  <div class="content">
    <el-container>
      <el-aside width="300px">
        <div class="left">
          <div class="block">
            <el-image
              style="width: 80px; height: 80px"
              class="left-header"
              :src="avater"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <span class="demonstration">{{ name }}</span>
            <span class="demonstration" style="font-size:14px;font-weight:normal;line-height:1;">服务商星级</span>
          </div>
        </div>
        <el-aside width="300px">
          <div class="left-list">
            <div class="left-lists">
              <!--纵向子级选项卡-->
              <ul
                :class="currentId == index ? 'isShow' : 'hidden'"
                v-for="(item, index) in leftList"
                :key="index"
              >
                <li
                  :class="currentID2 == index ? 'active1' : ' '"
                  v-for="(itemc, index) in leftList"
                  :key="index"
                  @click="handleChild(index)"
                >
                  <router-link :to="itemc.path"
                    ><div style="width: 100%">
                      {{ itemc.title }}
                    </div></router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </el-aside>
      </el-aside>
      <el-container>
        <el-main>
          <div class="right">
            <!--<div v-if="currentID2 == 0"><todoOrder></todoOrder></div>
            <div v-if="currentID2 == 1"><contribute></contribute></div>
            <div v-if="currentID2 == 2"><OrderPassed></OrderPassed></div>
            <div v-if="currentID2 == 3"><OrderStatistic></OrderStatistic></div>
            <div v-if="currentID2 == 4"><PassWord></PassWord></div>
            <div v-if="currentID2 == 5"><HashCode></HashCode></div>-->
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { showsidentify } from "../../API/identify.js"
//import OrderPassed from './OrderPassed/OrderPassed'
//import OrderStatistic from './OrderStatistic/OrderStatistic'
//import PassWord from './PassWord/PassWord'
//import HashCode from './HashCode/HashCode'
//import todoOrder from './ToDoOrder/ToDoOrder'
//import contribute from './Contribute/contribute'
export default {
  components: {
    //  OrderPassed,
    //  OrderStatistic,
    //  PassWord,
    //  HashCode,
    //   todoOrder,
    //	 contribute
  },
  data () {

    return {
      avater: require("../../assets/image/ygtx.jpg"),
      name: "",
      leftList: [
        { "title": '订单管理', "path": "/Staff/TodoOrder/?id=0" },
        // { "title": '投稿中订单', "path": "/Staff/contribution/?id=1" },
        // { "title": '已通过订单', "path": "/Staff/PassedOrder/?id=2" },
      //  { "title": '订单管理', "path": "/Staff/OrderStatistics/?id=3" },
        { "title": '安全设置', "path": "/Staff/changePassword/?id=4" },
        { "title": '专属二维码', "path": "/Staff/ecode/?id=5" },
        { "title": '分享链接', "path": '/Staff/Share/?id=6' },
        { title: "服务评价", path: "/Staff/order-common" },
     //   {"title":"订单管理","second_title":[{ "title": '代办订单', "path": "/Staff/TodoOrder/?id=0" },{"title":"已结算稿件","num":9,"link":"/Periodical/balanced/?id=9"}]}
      ],
      currentId: 0,
      currentID2: 0,
    }
  },
  methods: {
    handleChild: function (index) {
      this.currentID2 = index
      console.log(this.currentID2);
    },
    //弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;
    },
    showavater () {
      var code = localStorage.getItem('code');
      var id = localStorage.getItem('id');
      showsidentify(id, code).then(res => {
        console.log(res)
        if (res.data.data.headimg.length != 0) {
          this.avater = this.URL + res.data.data.headimg
        }
        this.name = res.data.data.loginname
        this.$root.$emit('next1') //调用父路由中监听的next方法,然后传两个参
      })
    }
  }, mounted () {
    if (this.$route.query.id) {
      this.currentID2 = this.$route.query.id
    }
    this.showavater()
    let _this = this //很重要把父路由的vue实例赋给_this
    _this.$root.$on('next', function () {  //监听next事件，这里$root很重要，不懂去官网介绍
      _this.showavater() //调用父路由中的方法
    })

  }
}
</script>

<style  scoped>
.content {
  width: 86%;
  height: auto;
  margin: 20px auto;
}
.left {
  width: 300px;
  height: 192px;
  background-color: #ffffff;
  border-radius: 5px;
}
.left-header {
  border-radius: 50%;
  margin: 40px 110px 0 110px;
}
.left-name {
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.demonstration {
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 40px;
  display: block;
  text-align: center;
}
.left-list {
  width: 300px;
  height: 350px;
  margin-top: 20px;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
}
.left-lists {
  width: 300px;
  height: 350px;
}
.left-list-wenzi {
  width: 300px;
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
}
.active1 {
  border-left: 2px solid #ff4318;
  background-color: rgba(0, 0, 0, 0.1);
}

.tabcont {
  padding: 20px;
  position: absolute;
  left: 220px;
  top: 65px;
  right: 0;
  z-index: 100;
}
.tabcontent {
  display: none;
}

.left-lists ul {
  list-style: none;
  overflow-y: auto;
}
.left-lists ul li {
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding-left: 20px;
  color: #333333;
}
.left-lists ul li span {
  display: block;
}
.isShow {
  display: block;
}
.hidden {
  display: none;
}
.el-main {
  padding: 0px 0px 20px 20px;
}
.right {
  width: 98%;
  height: auto;
  padding: 10px 0px 0px 15px;
  background: #ffffff;
}
</style>